@media  screen and (max-width: 800px) {

    header .up {

        background-position: top;
        padding: 0;

    }

    header .up .text p {

        display: none;

    }

    header .down .label {

        width: 100%;
        margin-bottom: 30px;

    }

    header .down {

        display: block;
    }

    header .menu {

        display: flex;
        justify-content: center;

    }

    #frame-2 {

        position: absolute;
        top: 270px;
        z-index: 20;
        left: 0;

    }

    header .menu .g-burger-acc {

        display: block;
    }
    header .menu .mob-nav {

        display: block;
        position: absolute;
        top: 6px;
        left: 40%;
        text-align: left;

    }
    #mob-burger:checked {

        ~ul {

            display: block;

        }
    }


    header .menu>.tab-frame ul {

        display: block;

    }

    #frame-1 {

        position: absolute;
        top: 20px;
        left: 0;

    }

    header .menu>.tab-frame ul>li {

        width: 100%;

        a {

            text-align: left;

        }

    }

    header .top-slider .slider .slide img {

        height: 150px;

    }

    main {

        display: block;

    }

    main .left,
    main .right {

        width: 100%;

    }

    main .mid {

        width: 100%;
        margin: 0;

    }

    main .left .v_menu ul {

        display: flex;
        flex-wrap: wrap;
    }

    main .left .v_menu ul li {

        min-width: 50%;
        border: 1px solid #2e7cbf;
        margin: 0 auto;
        width: 0;

    }

    main .left .v_menu ul li:last-child {


        flex-grow: 5;


    }

    main .left .v_menu ul li a {
        border: none;
        text-align: center;

    }
    main .left .v_menu ul li ul li {

        width: 100%;

    }

    main .right {

        display: none;

    }

    footer {

        padding-left: 20px;
    }
    footer .foot_main{

        display: block;
    }
    footer .foot_main .lft,
    footer .foot_main .rght{

        width: 100%;
        padding: 0;
    }

    header .menu>.tab-frame ul>li:hover ul{

        // display: none;
    }

    .tab-frame.inactive {

        display:none;
    }

    footer .footer_down {
    
        display: none;

    }

}
